import { MagicBellProvider } from '@magicbell/magicbell-react';
import { useLocalStorageState } from 'ahooks';
import { useCurrentUser, useMixpanel } from 'hooks';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CollapseButton, Ellipsis, TopNav } from 'tailwind';
import { magicBellTheme } from 'tailwind/TopNav/TopNavButtons/TopNavButtons.style';
import { User } from 'types/graphql';
import Menu, { MenuItems } from './Menu/Menu';
import { Resizable } from 're-resizable';
import { useQuery } from '@apollo/client';
import { ReactComponent as IdeaSvg } from 'assets/icons/Idea.svg';
import { ReactComponent as IdeaOutline } from 'assets/icons/IdeaOutline.svg';
import { ReactComponent as RoadmapOutline } from 'assets/icons/RoadMapOutline.svg';
import { ReactComponent as RoadmapSvg } from 'assets/icons/Roadmap.svg';
import GET_PROJECT_NAME from './apollo';
import { Direction } from 're-resizable/lib/resizer';
import { CloseIcon } from '@vucity/design_system';
import { IconButton } from '@material-ui/core';

interface LayoutProps {
  children: React.ReactNode;
  items: MenuItems[];
}

const MainLayout: React.FC<React.PropsWithChildren<LayoutProps>> = ({ items, children }) => {
  const { projectId: id } = useParams<{ projectId: string }>();
  const { currentUser } = useCurrentUser();
  const { pathname } = useLocation();
  const { mixpanelTrack } = useMixpanel();
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  const handleCloseBanner = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsBannerVisible(false);
    }, 300);
  };

  const { data } = useQuery(GET_PROJECT_NAME, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    variables: { id },
    skip: !id,
  });

  const renderProjectName = () =>
    id && (
      <div className="w-full mb-2 min-h-4 text-grey overflow-hidden whitespace-normal">
        {data?.project?.id && (
          <>
            <Ellipsis rows={3} className="text-lg font-medium text-grey" applyTooltip>
              {data?.project?.name}
            </Ellipsis>
          </>
        )}
      </div>
    );

  const bottomMenu = [
    {
      icon: <IdeaOutline color="primary" />,
      title: 'Submit an Idea',
      url: '/roadmap-submit-idea',
      activeIcon: <IdeaSvg color="primary" />,
    },
    {
      icon: <RoadmapOutline color="primary" />,
      title: 'View roadmap',
      url: '/roadmap',
      activeIcon: <RoadmapSvg color="primary" />,
    },
  ];

  const SIDEBAR_MIN_WIDTH = 84;
  const SIDEBAR_MAX_WIDTH = 280;
  const SIDEBAR_COLLAPSED_THRESHOLD = SIDEBAR_MIN_WIDTH * 2;

  const [sidebarSizeState, setSidebarSize] = useLocalStorageState('sidebarSize', { defaultValue: SIDEBAR_MAX_WIDTH });
  const sidebarSize = sidebarSizeState ?? SIDEBAR_MAX_WIDTH;
  const isSidebarCollapsed = sidebarSize <= SIDEBAR_COLLAPSED_THRESHOLD;

  const handleResize = (e: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    setSidebarSize(elementRef.offsetWidth);
  };

  const handleCollapseClick = () => {
    const action = isSidebarCollapsed ? 'expand' : 'collapse';
    setSidebarSize(isSidebarCollapsed ? SIDEBAR_MAX_WIDTH : SIDEBAR_MIN_WIDTH);
    mixpanelTrack(`Click to ${action} menu`, {
      Page: pathname,
    });
  };

  const sidebarCursor = sidebarSize === SIDEBAR_MIN_WIDTH ? 'e-resize' : sidebarSize === SIDEBAR_MAX_WIDTH ? 'w-resize' : 'col-resize';

  return (
    <MagicBellProvider apiKey={(currentUser as User)?.magicbellKey || ''} userEmail={currentUser?.email || ''} theme={magicBellTheme}>
      <TopNav />
      <div className="flex w-full overflow-hidden h-content">
        <Resizable
          defaultSize={{
            width: SIDEBAR_MAX_WIDTH,
          }}
          minWidth={SIDEBAR_MIN_WIDTH}
          maxWidth={SIDEBAR_MAX_WIDTH}
          size={{ width: sidebarSize, height: '100%' }}
          enable={{ top: false, right: true, bottom: false, left: false }}
          onResize={(e, direction, elementRef) => handleResize(e, direction, elementRef)}
          style={{ height: '100%', display: 'flex' }}
          handleStyles={{ right: { width: '6px', cursor: sidebarCursor } }}
          handleClasses={{
            right: 'bg-[#CACCD7] hover:!w-[8px] active:bg-[#94A9BC] hover:bg-[#94A9BC] transition-[background] duration-75',
          }}
          className="group/sidebar"
        >
          <div
            className={`flex flex-col whitespace-nowrap w-full transition-[max-width] min-w-[84px] duration-300
            justify-between p-3 relative ${isSidebarCollapsed ? 'max-w-full' : 'max-w-[280px]'}`}
          >
            <CollapseButton onClick={handleCollapseClick} collapsed={isSidebarCollapsed || false} className="group-hover/sidebar:flex hidden" />
            <div className="flex flex-col overflow-hidden">
              {renderProjectName()}
              <Menu items={items[0]} collapsed={isSidebarCollapsed} />
            </div>
            <div className="flex flex-col p-0">
              {!isSidebarCollapsed && <p className="pl-2 my-1 text-lg text-content">Give your feedback</p>}
              <Menu items={bottomMenu} collapsed={isSidebarCollapsed} />
            </div>
          </div>
        </Resizable>

        {/* <div
          className={`relative flex flex-col w-full h-full ${
            pathname === '/coveragemap' ? 'p-0' : 'p-3'
          } overflow-auto overflow-y-scroll text-content bg-primary-lightest`}
        >
          {children}
        </div> */}

        <div className="relative flex flex-col w-full h-full overflow-auto overflow-y-scroll text-content bg-primary-lightest">
          {pathname !== '/coveragemap' && isBannerVisible && (
            <div
              className={`w-full p-1 flex items-center bg-[#FDECBF] justify-between min-h-6 transition-all duration-300 ${
                isClosing ? 'opacity-0 transform -translate-y-4' : 'opacity-100'
              }`}
            >
              <div className="flex items-center grid-cols-[auto,auto] gap-x-1">
                <svg width={16} height={16} className="flex-shrink-0" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 5.28906H11V7.28906H9V5.28906ZM9 9.28906H11V15.2891H9V9.28906ZM10 0.289062C4.48 0.289062 0 4.76906 0 10.2891C0 15.8091 4.48 20.2891 10 20.2891C15.52 20.2891 20 15.8091 20 10.2891C20 4.76906 15.52 0.289062 10 0.289062ZM10 18.2891C5.59 18.2891 2 14.6991 2 10.2891C2 5.87906 5.59 2.28906 10 2.28906C14.41 2.28906 18 5.87906 18 10.2891C18 14.6991 14.41 18.2891 10 18.2891Z"
                    fill="black"
                  />
                </svg>
                <p className="leading-[16px]">
                  <span className="block leading-normal">
                    We're experiencing cloud performance issues due to a third-party service provider. To maintain performance during a cloud session, please
                    stay in the tab or use Firefox. A fix is in progress and may require some users to update their whitelist.
                  </span>
                </p>
              </div>
              <div className="flex items-center gap-x-1">
                <IconButton aria-label="close" onClick={handleCloseBanner}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          )}
          <div className={`${pathname === '/coveragemap' ? 'p-0' : 'p-3'} relative flex flex-col w-full h-full`}>{children}</div>
        </div>
      </div>
    </MagicBellProvider>
  );
};

export default MainLayout;
